export interface PatientNotification {
    patient_notification_id: number;
    patient_id: number;
    url: string;
    creation_date: Date;
    accessed: boolean;
    doctor_name: string;
    prescription_creation_date: Date
    type: string;
}

export enum PatientNotificationTypeEnum {
    NewPrescription = 'NewPrescription',
    NewResource = 'NewResource',
    NewMultipleResources = 'NewMultipleResources',
    NewAuthorization = 'NewAuthorization',
    PrescriptionDeleted = 'PrescriptionDeleted',
    PrescriptionResourceDeleted = 'PrescriptionResourceDeleted',
    PrescriptionResourceSequenceUpdated = 'PrescriptionResourceSequenceUpdated',
    PrescriptionReminder = 'PrescriptionReminder',
    TrajectoryPhaseNewContent = 'TrajectoryPhaseNewContent',
}