import { Badge, Box, Divider, Typography } from '@mui/material';
import makeStyles from '@mui/styles/makeStyles';
import type { FC } from 'react';
import {
    Clipboard as ClipboardIcon,
    User as UserIcon,
} from 'react-feather';
import { FormattedMessage, useIntl } from 'react-intl';
import { useHistory } from 'react-router';
import { PatientNotification, PatientNotificationTypeEnum } from 'src/types/patient_notification';
import { formatDate } from 'src/views/utils/Utils';

interface NotificationsContentProps {
    notificationList: PatientNotification[];
    onClose: () => void;
    handleConfigurationOpen: (directly_open_authorizations?: boolean) => void;
}

const useStyles = makeStyles((theme) => ({
    notificationBox: {
        display: 'inline-block',
        backgroundColor: '#1CA58A',
        width: '35px',
        height: '35px',
        borderRadius: '50%',
        lineHeight: '31px',
        marginRight: '8px',
    },
}));

const NotificationsContent: FC<NotificationsContentProps> = ({ notificationList, onClose, handleConfigurationOpen }) => {
    const history = useHistory();
    const classes = useStyles();
    const intl = useIntl();

    const handleNotificationClick = (notification: PatientNotification) => {
        if (notification.type === PatientNotificationTypeEnum.NewAuthorization) {
            handleConfigurationOpen(true);
        }
        else {
            if (notification.type !== PatientNotificationTypeEnum.PrescriptionDeleted) {
                history.push(notification.url);
                onClose();
                window.location.reload();
            }
        }

    }

    const renderNewPrescriptionNotification = (notification: PatientNotification) => {
        return (
            <Box m={'1rem'} onClick={() => { handleNotificationClick(notification) }}>
                <Box display={'flex'} justifyContent={'space-between'}>
                    <Box display={'flex'} >
                        <div className={classes.notificationBox}>
                            <Badge color="error" variant="dot" invisible={notification.accessed} style={{ marginLeft: '5.5px', }}>
                                <ClipboardIcon style={{ color: '#FFFFFF', }} />
                            </Badge>
                        </div>
                        <Typography variant='body2'>
                            <FormattedMessage id="notifications.notificationsContent.new_prescription" />
                        </Typography>
                    </Box>
                    <Typography variant='caption'>
                        {formatDate(notification.creation_date)}
                    </Typography>
                </Box>
                <Box display={'flex'}>
                    <Typography variant='body2' style={{ marginLeft: '43px' }}>
                        <FormattedMessage id="notifications.notificationsContent.new_prescription_text" values={{ doctor_name: notification.doctor_name }} />
                    </Typography>
                </Box>
            </Box>
        )
    }

    const renderNewResourceNotification = (notification: PatientNotification) => {
        return (
            <Box m={'1rem'} onClick={() => { handleNotificationClick(notification) }}>
                <Box display={'flex'} justifyContent={'space-between'}>
                    <Box display={'flex'}>
                        <div className={classes.notificationBox}>
                            <Badge color="error" variant="dot" invisible={notification.accessed} style={{ marginLeft: '5.5px', }}>
                                <ClipboardIcon style={{ color: '#FFFFFF', }} />
                            </Badge>
                        </div>
                        <Typography variant='body2'>
                            <FormattedMessage id="notifications.notificationsContent.new_resource" />
                        </Typography>
                    </Box>
                    <Typography variant='caption'>
                        {formatDate(notification.creation_date)}
                    </Typography>
                </Box>
                <Box display={'flex'}>
                    <Typography variant='body2' style={{ marginLeft: '43px' }}>
                        <FormattedMessage id="notifications.notificationsContent.new_resource_text" values={{ doctor_name: notification.doctor_name }} />
                    </Typography>
                </Box>
            </Box>
        )
    }

    const renderNewMultipleResourcesNotification = (notification: PatientNotification) => {
        return (
            <Box m={'1rem'} onClick={() => { handleNotificationClick(notification) }}>
                <Box display={'flex'} justifyContent={'space-between'}>
                    <Box display={'flex'}>
                        <div className={classes.notificationBox}>
                            <Badge color="error" variant="dot" invisible={notification.accessed} style={{ marginLeft: '5.5px', }}>
                                <ClipboardIcon style={{ color: '#FFFFFF', }} />
                            </Badge>
                        </div>
                        <Typography variant='body2'>
                            <FormattedMessage id="notifications.notificationsContent.new_multiple_resources" />
                        </Typography>
                    </Box>
                    <Typography variant='caption'>
                        {formatDate(notification.creation_date)}
                    </Typography>
                </Box>
                <Box display={'flex'}>
                    <Typography variant='body2' style={{ marginLeft: '43px' }}>
                        <FormattedMessage id="notifications.notificationsContent.new_multiple_resources_text" values={{ doctor_name: notification.doctor_name }} />
                    </Typography>
                </Box>
            </Box>
        )
    }

    const renderNewAuthorizationNotification = (notification: PatientNotification) => {
        return (
            <Box m={'1rem'} onClick={() => { handleNotificationClick(notification) }}>
                <Box display={'flex'} justifyContent={'space-between'}>
                    <Box display={'flex'}>
                        <div className={classes.notificationBox}>
                            <Badge color="error" variant="dot" invisible={notification.accessed} style={{ marginLeft: '5.5px', }}>
                                <UserIcon style={{ color: '#FFFFFF', }} />
                            </Badge>
                        </div>
                        <Typography variant='body2'>
                            <FormattedMessage id="notifications.notificationsContent.new_authorization" />
                        </Typography>
                    </Box>
                    <Typography variant='caption'>
                        {formatDate(notification.creation_date)}
                    </Typography>
                </Box>
                <Box display={'flex'}>
                    <Typography variant='body2' style={{ marginLeft: '43px' }}>
                        <FormattedMessage id="notifications.notificationsContent.new_authorization_text" values={{ doctor_name: notification.doctor_name }} />
                    </Typography>
                </Box>
            </Box>
        )
    }

    const renderPrescriptionDeletedNotification = (notification: PatientNotification) => {
        return (
            <Box m={'1rem'} onClick={() => { handleNotificationClick(notification) }}>
                <Box display={'flex'} justifyContent={'space-between'}>
                    <Box display={'flex'}>
                        <div className={classes.notificationBox}>
                            <Badge color="error" variant="dot" invisible={notification.accessed} style={{ marginLeft: '5.5px', }}>
                                <UserIcon style={{ color: '#FFFFFF', }} />
                            </Badge>
                        </div>
                        <Typography variant='body2'>
                            <FormattedMessage id="notifications.notificationsContent.prescription_deleted" />
                        </Typography>
                    </Box>
                    <Typography variant='caption'>
                        {formatDate(notification.creation_date)}
                    </Typography>
                </Box>
                <Box display={'flex'}>
                    <Typography variant='body2' style={{ marginLeft: '43px' }}>
                        <FormattedMessage id="notifications.notificationsContent.prescription_deleted_text" values={{
                            doctor_name: notification.doctor_name,
                            prescription_creation_date: new Date(notification.prescription_creation_date).toLocaleDateString(intl.locale, { year: 'numeric', month: 'long', day: 'numeric' })
                        }} />
                    </Typography>
                </Box>
            </Box>
        )
    }
    const renderPrescriptionResourceDeletedNotification = (notification: PatientNotification) => {
        return (
            <Box m={'1rem'} onClick={() => { handleNotificationClick(notification) }}>
                <Box display={'flex'} justifyContent={'space-between'}>
                    <Box display={'flex'}>
                        <div className={classes.notificationBox}>
                            <Badge color="error" variant="dot" invisible={notification.accessed} style={{ marginLeft: '5.5px', }}>
                                <UserIcon style={{ color: '#FFFFFF', }} />
                            </Badge>
                        </div>
                        <Typography variant='body2'>
                            <FormattedMessage id="notifications.notificationsContent.prescription_resource_deleted" />
                        </Typography>
                    </Box>
                    <Typography variant='caption'>
                        {formatDate(notification.creation_date)}
                    </Typography>
                </Box>
                <Box display={'flex'}>
                    <Typography variant='body2' style={{ marginLeft: '43px' }}>
                        <FormattedMessage id="notifications.notificationsContent.prescription_resource_deleted_text" values={{ doctor_name: notification.doctor_name }} />
                    </Typography>
                </Box>
            </Box>
        )
    }

    const renderPrescriptionResourceSequenceUpdatedNotification = (notification: PatientNotification) => {
        return (
            <Box m={'1rem'} onClick={() => { handleNotificationClick(notification) }}>
                <Box display={'flex'} justifyContent={'space-between'}>
                    <Box display={'flex'}>
                        <div className={classes.notificationBox}>
                            <Badge color="error" variant="dot" invisible={notification.accessed} style={{ marginLeft: '5.5px', }}>
                                <UserIcon style={{ color: '#FFFFFF', }} />
                            </Badge>
                        </div>
                        <Typography variant='body2'>
                            <FormattedMessage id="notifications.notificationsContent.prescription_resource_sequence_updated" />
                        </Typography>
                    </Box>
                    <Typography variant='caption'>
                        {formatDate(notification.creation_date)}
                    </Typography>
                </Box>
                <Box display={'flex'}>
                    <Typography variant='body2' style={{ marginLeft: '43px' }}>
                        <FormattedMessage id="notifications.notificationsContent.prescription_resource_sequence_updated_text" values={{ doctor_name: notification.doctor_name }} />
                    </Typography>
                </Box>
            </Box>
        )
    }

    const renderReminderPrescriptionNotification = (notification: PatientNotification) => {
        return (
            <Box m={'1rem'} onClick={() => { handleNotificationClick(notification) }}>
                <Box display={'flex'} justifyContent={'space-between'}>
                    <Box display={'flex'} >
                        <div className={classes.notificationBox}>
                            <Badge color="error" variant="dot" invisible={notification.accessed} style={{ marginLeft: '5.5px', }}>
                                <ClipboardIcon style={{ color: '#FFFFFF', }} />
                            </Badge>
                        </div>
                        <Typography variant='body2'>
                            <FormattedMessage id="notifications.notificationsContent.reminder_prescription" />
                        </Typography>
                    </Box>
                    <Typography variant='caption'>
                        {formatDate(notification.creation_date)}
                    </Typography>
                </Box>
                <Box display={'flex'}>
                    <Typography variant='body2' style={{ marginLeft: '43px' }}>
                        <FormattedMessage id="notifications.notificationsContent.reminder_prescription_text" values={{ doctor_name: notification.doctor_name }} />
                    </Typography>
                </Box>
            </Box>
        )
    }

    const renderTrajectoryPhaseNewContentNotification = (notification: PatientNotification) => {
        return (
            <Box m={'1rem'} onClick={() => { handleNotificationClick(notification) }}>
                <Box display={'flex'} justifyContent={'space-between'}>
                    <Box display={'flex'}>
                        <div className={classes.notificationBox}>
                            <Badge color="error" variant="dot" invisible={notification.accessed} style={{ marginLeft: '5.5px', }}>
                                <UserIcon style={{ color: '#FFFFFF', }} />
                            </Badge>
                        </div>
                        <Typography variant='body2'>
                            <FormattedMessage id="notifications.notificationsContent.trajectory_phase_new_content" />
                        </Typography>
                    </Box>
                    <Typography variant='caption'>
                        {formatDate(notification.creation_date)}
                    </Typography>
                </Box>
                <Box display={'flex'}>
                    <Typography variant='body2' style={{ marginLeft: '43px' }}>
                        <FormattedMessage id="notifications.notificationsContent.trajectory_phase_new_content_text" values={{ doctor_name: notification.doctor_name }} />
                    </Typography>
                </Box>
            </Box>
        )
            
    }

    return (
        <Box sx={{ maxHeight: '85vh', overflowY: 'auto' }}>
            {notificationList && notificationList.map((notification) => (
                <Box key={notification.patient_notification_id}>
                    <Divider />
                    {notification.type === PatientNotificationTypeEnum.NewPrescription && (
                        renderNewPrescriptionNotification(notification)
                    )}
                    {notification.type === PatientNotificationTypeEnum.NewResource && (
                        renderNewResourceNotification(notification)
                    )}
                    {notification.type === PatientNotificationTypeEnum.NewMultipleResources && (
                        renderNewMultipleResourcesNotification(notification)
                    )}
                    {notification.type === PatientNotificationTypeEnum.NewAuthorization && (
                        renderNewAuthorizationNotification(notification)
                    )}
                    {notification.type === PatientNotificationTypeEnum.PrescriptionDeleted && (
                        renderPrescriptionDeletedNotification(notification)
                    )}
                    {notification.type === PatientNotificationTypeEnum.PrescriptionResourceDeleted && (
                        renderPrescriptionResourceDeletedNotification(notification)
                    )}
                    {notification.type === PatientNotificationTypeEnum.PrescriptionResourceSequenceUpdated && (
                        renderPrescriptionResourceSequenceUpdatedNotification(notification)
                    )}
                    {notification.type === PatientNotificationTypeEnum.PrescriptionReminder && (
                        renderReminderPrescriptionNotification(notification)
                    )}
                    {notification.type === PatientNotificationTypeEnum.TrajectoryPhaseNewContent && (
                        renderTrajectoryPhaseNewContentNotification(notification)
                    )}
                </Box>
            ))}
        </Box>
    );
}

export default NotificationsContent;
