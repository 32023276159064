import * as sanitizeHtml from 'sanitize-html';
import parse from "html-react-parser";
import axios from 'axios';
import { useIntl } from 'react-intl';
import { Prescription } from 'src/types/prescription';

export const getWSConfig = () => {
    let token = localStorage.getItem('accessToken');
    let config = {
        headers: { Authorization: `Bearer ${token}` },
    };
    return config;
}

/**
 * 
 * @param htmlText a string with html tags and text
 * @returns a parsed html compiled with only safe tags
 */
export const cleanHtml = (htmlText) => {
    const cleanHtml = sanitizeHtml(htmlText, {
        allowedTags: ['b', 'i', 'em', 'strong', 'a', 'p', 'br', 'li', 'ol', 'ul', 'u', 'h1', 'h2', 'h3', 'h4', 'h5'],
        allowedAttributes: {
            a: ['href', 'target']
        }
    });
    return parse(cleanHtml);
}

export const getAndStoreKalturaVideoPlayerId = async (organization_id?: number) => {

    let params = { "organization_id": 0 }
    if (!organization_id) {
        let user = JSON.parse(localStorage.getItem('user'));

        if (user && user.organization_id) {
            params = { "organization_id": user.organization_id }
        }
    }
    else {
        params = { "organization_id": organization_id }
    }

    try {
        const response = await axios.post(process.env.REACT_APP_SERVER_URL + '/organization/1.0/get/kaltura/video/player/id', params);
        if (response && response.data) {
            localStorage.setItem('kaltura_video_player_id_patient_portal', JSON.stringify(response.data));
            return JSON.stringify(response.data);
        }
    } catch (error) {
        console.log(error);
        return '';
    }
}

export const deleteKalturaVideoPlayerIdFromLocalStorage = () => {
    localStorage.removeItem('kaltura_video_player_id_patient_portal');
}

export const getPatientPortalUrl = (): string => {
    if (process.env.REACT_APP_BUILD_ENV === 'local') {
        return 'http://localhost:3002/';
    }
    else if (process.env.REACT_APP_BUILD_ENV === 'dev') {
        return 'https://dev.app.digimevo.com/';
    }
    else if (process.env.REACT_APP_BUILD_ENV === 'uat') {
        return 'https://uat.app.digimevo.com/';
    }
    else if (process.env.REACT_APP_BUILD_ENV === 'prod') {
        return 'https://www.app.digimevo.com/';
    }
    else if (process.env.REACT_APP_BUILD_ENV === 'sescam-uat') {
        return 'https://canalsaludpre.sanidad.castillalamancha.es/'
    }
    else if (process.env.REACT_APP_BUILD_ENV === 'sescam-prod') {
        return 'https://sescam-prod.app.digimevo.com/'
    }
};

export const formatDate = (date: Date) => {

    if (!date) return '-';

    const today = new Date();
    const dateObj = new Date(date);

    if (
        dateObj.getDate() === today.getDate() &&
        dateObj.getMonth() === today.getMonth() &&
        dateObj.getFullYear() === today.getFullYear()
    ) {
        return `${dateObj.getHours()}:${dateObj.getMinutes().toString().padStart(2, '0')}`;
    } else {
        const day = dateObj.getDate().toString().padStart(2, '0');
        const month = (dateObj.getMonth() + 1).toString().padStart(2, '0'); // Months are zero-based
        const year = dateObj.getFullYear();
        return `${day}-${month}-${year}`;
    }

};

