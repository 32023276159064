import { useState, useEffect, useContext } from 'react';
import type { FC } from 'react';
import clsx from 'clsx';
import * as Yup from 'yup';
import PropTypes from 'prop-types';
import { Formik } from 'formik';
import moment from 'moment';
import { Box, Button, TextField, Typography, CardContent, Card, MenuItem, Select, InputLabel, FormControl } from '@mui/material';
import makeStyles from '@mui/styles/makeStyles';
import { Alert } from '@mui/material';
import DatePicker from '@mui/lab/DatePicker';
import useIsMountedRef from 'src/hooks/useIsMountedRef';
import axios from 'axios';
import type { Patient } from 'src/types/patient';
import type { SelectOption } from 'src/types/selectOption';
import "moment/locale/es";
import { getWSConfig } from 'src/views/utils/Utils';
import { useSnackbar } from 'notistack';
import Divider from '@mui/material/Divider';
import PhoneInput from 'react-phone-input-2'
import 'react-phone-input-2/lib/material.css'
import { isValidPhoneNumber } from 'react-phone-number-input'
import { localLogError } from 'src/views/utils/logger';
import { FormattedMessage, useIntl } from 'react-intl';
import { Language } from 'src/types/language';
import { useHistory } from 'react-router-dom';
import AuthContext from 'src/contexts/JWTAuthContext';

interface PatientDataFormProps {
    className?: string;
}

const useStyles = makeStyles((theme) => ({

    root: {},
    chip: {
        marginLeft: 4,
        marginRight: 4,
        marginTop: 8,
    },
    phoneTextfield: {
        "&.react-tel-input .special-label": {
            fontSize: '11px',
            left: '0.5rem',
            fontFamily: 'Roboto'
        },
        "&.react-tel-input .form-control:focus": {
            borderColor: theme.palette.primary.main,
            boxShadow: `0px 0px 0px 1px ${theme.palette.primary.main}`,
        },
        "&.react-tel-input .invalid-number-message": {
            left: '0.5rem'
        }
    },
    inputPhone: {
        width: "100%",
        borderColor: "#D14343"
    }
}));

const PatientDataForm: FC<PatientDataFormProps> = ({ className, ...rest }) => {
    const classes = useStyles();
    const isMountedRef = useIsMountedRef();
    const phoneRegExp = /(^\+([0-9]){7,15}$)|(^([0-9]){9}$)/
    const lastNameRegExp = /^[A-Za-zàèìòùáéíóúÀÈÌÒÙÁÉÍÓÚäëïöüÄËÏÖÜñÑçÇ -]*$/;
    const cityRegExp = /^[A-Za-zàèìòùáéíóúÀÈÌÒÙÁÉÍÓÚäëïöüÄËÏÖÜñÑçÇ]([ A-Za-zàèìòùáéíóúÀÈÌÒÙÁÉÍÓÚäëïöüÄËÏÖÜñÑçÇ]+)?$/
    const patient: Patient = JSON.parse(localStorage.getItem('user'));
    const config = getWSConfig();
    const { enqueueSnackbar } = useSnackbar();
    const intl = useIntl();
    const [genders, setGenders] = useState([]);
    const [languageList, setLanguageList] = useState<Language[]>(null);
    const { logout } = useContext(AuthContext);

    const history = useHistory();

    const getLanguageList = () => {
        axios.get(process.env.REACT_APP_SERVER_URL + '/language/1.0/get/all', config)
            .then(function (response) {
                if (response && response.data) {
                    setLanguageList(response.data)
                }
            })
            .catch(function (error) {
                console.log(error);
            });
    }

    useEffect(() => {
        axios.post(process.env.REACT_APP_SERVER_URL + '/gender/1.0/get/all/by/patient/language', config)
            .then(function (response) {
                if (response !== undefined && response.data !== undefined) {
                    setGenders(response.data);
                }
            })
            .catch(function (error) {
                localLogError("", error);
            })

        getLanguageList()
    }, []);

    const getBorderColor = (error: boolean): string => {
        if (error) return 'red'
        else return '#E6E8F0'
    }

    const getLabelColor = (error: boolean): string => {
        if (error) return 'red'
        else return '#6B7280';
    }

    if (genders.length === 0) {
        return null;
    }
    else {
        return (
            <Formik
                initialValues={{
                    name: patient.name,
                    lastName: patient.last_name,
                    gender_id: patient.gender_id,
                    language_id: patient.language_id,
                    birthdate: moment(patient.birth_date, "YYYY-MM-DD"),
                    city: patient.city,
                    region: patient.region,
                    mobile: patient.mobile,
                    success: "false",
                    submit: null
                }}
                validationSchema={Yup.object().shape({
                    name: Yup.string().max(255).required(intl.formatMessage({ id: "accountManagment.Profile.PatientDataForm.enter_name" })).matches(lastNameRegExp,
                        intl.formatMessage({ id: "accountManagment.Profile.PatientDataForm.invalid_name" })),
                    lastName: Yup.string().max(255).required(intl.formatMessage({ id: "accountManagment.Profile.PatientDataForm.enter_last_name" })).matches(lastNameRegExp,
                        intl.formatMessage({ id: "accountManagment.Profile.PatientDataForm.invalid_last_name" })),
                    gender_id: Yup.string().required(intl.formatMessage({ id: "accountManagment.Profile.PatientDataForm.enter_gender" })),
                    birthdate: Yup.date().required(intl.formatMessage({ id: "accountManagment.Profile.PatientDataForm.enter_correct_date" })),
                    city: Yup.string().matches(cityRegExp, intl.formatMessage({ id: "accountManagment.Profile.PatientDataForm.invalid_city" })),
                    mobile: Yup.string().test(
                        'phone-valid', intl.formatMessage({ id: "accountManagment.Profile.PatientDataForm.invalid_phone" }),
                        function (value) {
                            if (value === "+") return true
                            if (value && value != "+") return isValidPhoneNumber(value)
                            return false
                        })
                })}
                onSubmit={async (values, {
                    setErrors,
                    setStatus,
                    setSubmitting
                }) => {
                    try {

                        let changed: boolean = false;
                        let params = {};

                        if (values.name !== patient.name) {
                            params['name'] = values.name
                            changed = true
                        }
                        if (values.lastName !== patient.last_name) {
                            params['last_name'] = values.lastName
                            changed = true
                        }
                        if (values.gender_id !== patient.gender_id) {
                            params['gender_id'] = parseInt(values.gender_id.toString())
                            changed = true
                        }
                        if (values.language_id !== patient.language_id) {
                            params['language_id'] = values.language_id
                            params['language'] = languageList.find(language => language.language_id === values.language_id).language_code
                            changed = true
                        }

                        if (moment(values.birthdate).format("YYYY-MM-DD") !== patient.birth_date) {
                            if (values.birthdate && moment(values.birthdate).isValid()) {
                                params['birth_date'] = moment(values.birthdate).format("YYYY-MM-DD");
                            }
                            changed = true
                        }
                        if (values.city !== patient.city) {
                            if (values.city === "") params['city'] = null;
                            else params['city'] = values.city;
                            changed = true
                        }
                        if (values.mobile !== patient.mobile) {
                            if (values.mobile === "") params['mobile'] = null;
                            else {
                                params['mobile'] = values.mobile.replace(/\s/g, '');
                                params['mobile'] = params['mobile'].replace(/[()]/g, '')
                                params['mobile'] = params['mobile'].replace(/-/g, '')
                            }
                            changed = true
                        }
                        if (values.region !== patient.region) {
                            if (values.region === "") params['region'] = null;
                            else params['region'] = values.region;
                            changed = true
                        }
                        if (!changed) {
                            setErrors({ submit: intl.formatMessage({ id: "accountManagment.Profile.PatientDataForm.modify_field_error" }) });
                        } else {
                            axios.patch(process.env.REACT_APP_SERVER_URL + '/auth/1.0/patient', params, config)
                                .then(function (response) {
                                    if (response.data.result === "success") {
                                        enqueueSnackbar(intl.formatMessage({ id: "accountManagment.Profile.PatientDataForm.changes_saved_successfully" }), { variant: 'success', persist: false, color: 'green' });
                                    }
                                    window.setTimeout(() => {
                                        window.location.reload();
                                    }, 1500);
                                })
                                .catch(function (error) {
                                    console.error(error.response.data);
                                    if (error.response && error.response.status === 401) {
                                        enqueueSnackbar(<FormattedMessage id="session_expired" />, { variant: 'error' });
                                        logout();
                                    }
                                    else if (error.response.data.statusCode === 409) {
                                        setErrors({ submit: intl.formatMessage({ id: "accountManagment.Profile.PatientDataForm.user_exists_error" }, { email: params['email'] }) });
                                    } else {
                                        setErrors({ submit: intl.formatMessage({ id: "accountManagment.Profile.PatientDataForm.general_error" }) });
                                    }
                                })
                        }

                        if (isMountedRef.current) {
                            setStatus({ success: true });
                            setSubmitting(false);
                        }
                    } catch (err) {
                        console.error(err);
                        setStatus({ success: false });
                        setErrors({ submit: err.message });
                        setSubmitting(false);
                    }
                }}
            >
                {({
                    errors,
                    handleBlur,
                    handleChange,
                    handleSubmit,
                    isSubmitting,
                    setFieldValue,
                    setFieldTouched,
                    touched,
                    values
                }) => (
                    <form
                        noValidate
                        className={clsx(classes.root, className)}
                        onSubmit={handleSubmit}
                        {...rest}
                    >
                        <Card>
                            <CardContent>
                                <Box>
                                    <Box style={{ float: 'left', width: '48%' }}>
                                        <TextField
                                            error={Boolean(touched.name && errors.name)}
                                            fullWidth
                                            helperText={touched.name && errors.name}
                                            label={<FormattedMessage id="accountManagment.Profile.PatientDataForm.first_name" />}
                                            margin="normal"
                                            name="name"
                                            onBlur={handleBlur}
                                            onChange={handleChange}
                                            value={values.name}
                                            variant="outlined"
                                            style={{ marginBottom: '10px' }}
                                        />
                                        <DatePicker
                                            maxDate={moment().toDate()}
                                            inputFormat="dd/MM/yyyy"
                                            value={moment(values.birthdate).toDate()}
                                            onChange={(date) => setFieldValue('birthdate', date)}
                                            label={<FormattedMessage id="accountManagment.Profile.PatientDataForm.birthdate" />}
                                            renderInput={(props) => (
                                                <TextField {...props}
                                                    helperText={Boolean(touched.birthdate && errors.birthdate) && <FormattedMessage id="accountManagment.Profile.PatientDataForm.enter_correct_date" />}
                                                    onBlur={() => setFieldTouched('birthdate')}
                                                    name="birthdate"
                                                    fullWidth
                                                    margin="normal"
                                                    error={Boolean(touched.birthdate && errors.birthdate)}
                                                    {...props}
                                                    style={{ marginBottom: '10px' }}
                                                />
                                            )}
                                        >
                                        </DatePicker>
                                        <TextField
                                            error={Boolean(touched.region && errors.region)}
                                            fullWidth
                                            helperText={touched.region && errors.region}
                                            label={<FormattedMessage id="accountManagment.Profile.PatientDataForm.state_region" />}
                                            margin="normal"
                                            name="region"
                                            onBlur={handleBlur}
                                            onChange={handleChange}
                                            value={values.region}
                                            variant="outlined"
                                            style={{ marginBottom: '25px' }}
                                        />

                                        <FormControl fullWidth>
                                            <InputLabel id="demo-simple-select-label"><FormattedMessage id="accountManagment.Profile.PatientDataForm.language" /></InputLabel>
                                            <Select
                                                label="language_id"
                                                defaultValue={values.language_id}
                                                value={values.language_id}
                                                onChange={(value) => setFieldValue('language_id', value.target.value)}
                                            >
                                                {languageList && languageList.map((language) => (
                                                    <MenuItem value={language.language_id}>{language.text_to_show[language.language_code]}</MenuItem>
                                                )
                                                )}
                                            </Select>
                                        </FormControl>
                                    </Box>
                                </Box>
                                <Box style={{ float: 'right', width: '48%' }}>
                                    <TextField
                                        error={Boolean(touched.lastName && errors.lastName)}
                                        fullWidth
                                        helperText={touched.lastName && errors.lastName}
                                        label={<FormattedMessage id="accountManagment.Profile.PatientDataForm.last_name" />}
                                        margin="normal"
                                        name="lastName"
                                        onBlur={handleBlur}
                                        onChange={handleChange}
                                        value={values.lastName}
                                        variant="outlined"
                                        style={{ marginBottom: '25px' }}
                                    />
                                    <PhoneInput
                                        country={'us'}
                                        value={values.mobile}
                                        inputProps={{ name: "mobile" }}
                                        specialLabel={intl.formatMessage({ id: "accountManagment.Profile.PatientDataForm.phone" })}
                                        onChange={(phoneNumber, country, e) => {
                                            handleChange(e)
                                        }}
                                        onBlur={handleBlur}
                                        isValid={(inputNumber) => {
                                            return isValidPhoneNumber("+" + inputNumber)
                                        }
                                        }
                                        containerClass={classes.phoneTextfield}
                                        containerStyle={{
                                            color: getLabelColor(Boolean(touched.mobile && errors.mobile))
                                        }}
                                        inputStyle={{
                                            width: "100%",
                                            borderRadius: '8px',
                                            borderColor: getBorderColor(Boolean(touched.mobile && errors.mobile)),
                                        }}
                                        dropdownStyle={{
                                            color: ''
                                        }}
                                    />
                                    {
                                        Boolean(touched.mobile && errors.mobile) &&
                                        <Typography
                                            sx={{
                                                fontSize: '12px',
                                                fontWeight: 400,
                                                letterSpacing: '0.4px',
                                                textAlign: 'left',
                                                marginTop: '3px',
                                                marginRight: '14px',
                                                marginBottom: '10px',
                                                marginLeft: '14px',
                                                color: '#D14343'
                                            }}>
                                            <FormattedMessage id="accountManagment.Profile.PatientDataForm.enter_valid_phone" />
                                        </Typography>

                                    }
                                    <Box
                                        mt={'1.6rem'}
                                        display="flex"
                                        alignItems="center"
                                        style={{ marginBottom: '10px' }}
                                    >
                                        <FormControl fullWidth
                                            error={Boolean(errors.gender_id && touched.gender_id)}>
                                            <InputLabel id="demo-simple-select-label"><FormattedMessage id="accountManagment.Profile.PatientDataForm.gender" /></InputLabel>
                                            <Select
                                                label="gender_id"
                                                defaultValue={values.gender_id}
                                                value={values.gender_id}
                                                onChange={(value) => setFieldValue('gender_id', value.target.value)}
                                            >
                                                {Object.keys(genders).map((key) => (
                                                    <MenuItem key={key} value={key}>
                                                        {genders[key]}
                                                    </MenuItem>
                                                ))}
                                            </Select>
                                            {Boolean(errors.gender_id && touched.gender_id) && (
                                                <Typography
                                                    sx={{
                                                        fontSize: '12px',
                                                        fontWeight: 400,
                                                        letterSpacing: '0.4px',
                                                        textAlign: 'left',
                                                        marginTop: '3px',
                                                        marginRight: '14px',
                                                        marginBottom: '10px',
                                                        marginLeft: '14px',
                                                        color: '#D14343'
                                                    }}>
                                                    <FormattedMessage id="accountManagment.Profile.PatientDataForm.enter_gender" />
                                                </Typography>
                                            )}
                                        </FormControl>

                                    </Box>
                                    <TextField
                                        error={Boolean(touched.city && errors.city)}
                                        fullWidth
                                        helperText={touched.city && errors.city}
                                        label={<FormattedMessage id="accountManagment.Profile.PatientDataForm.city" />}
                                        margin="normal"
                                        name="city"
                                        onBlur={handleBlur}
                                        onChange={handleChange}
                                        value={values.city}
                                        variant="outlined"
                                        style={{ marginBottom: '10px' }}
                                    />
                                </Box>
                                <Box style={{ clear: 'both', paddingTop: '1rem', marginBottom: '1.5rem' }}
                                    alignItems="center"
                                    display="flex"
                                    mt={2}
                                    ml={1}
                                >
                                    <Typography
                                        variant="caption"
                                        color="textSecondary"
                                    >
                                        <FormattedMessage id="accountManagment.Profile.PatientDataForm.mandatory_fields" />
                                    </Typography>
                                </Box>
                                {errors.submit && (
                                    <Box mt={3}>
                                        <Alert severity="error">{errors.submit}</Alert>
                                    </Box>
                                )}
                                <Divider />
                                <Box mt={4}>
                                    <Button
                                        color="primary"
                                        disabled={isSubmitting}
                                        size="medium"
                                        type="submit"
                                        variant="contained"
                                        style={{ float: 'right', marginBottom: '1rem' }}
                                    >
                                        <FormattedMessage id="accountManagment.Profile.PatientDataForm.save_changes" />
                                    </Button>
                                </Box>
                            </CardContent>
                        </Card>
                    </form>
                )}
            </Formik>
        );
    }
};

export default PatientDataForm;
